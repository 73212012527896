// import UIKIT

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

// HTMX and hyperscript
import htmx from "htmx.org";
window.htmx = require('htmx.org');
import hyperscript from "hyperscript.org";

// import JQUERY

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
window.jQuery = jQuery;

// import inputmask lib

import Inputmask from "inputmask";

Inputmask.extendDefaults({
    'placeholder': ' '
});

$(document).ready(function() {
    Inputmask().mask(document.querySelectorAll("input"));
});

// import CSS

import './auth.scss';

export {UIkit,$,Inputmask, htmx, hyperscript};